import React from 'react'
// import PropTypes from 'prop-types'
import shortid from 'shortid'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import JSURL from 'jsurl'

import formatPrice from '../../utils/formatPrice'
import _t from '../../utils/translate'
import QuantityField from '../../utils/QuantityField'
import SelectionButton from '../SelectionButton'

import { addToCart } from '../../actions/cartActions'

class OligotherapyDisplay extends React.Component {
    constructor(props) {
        super(props)
        this.onChange = this.onChange.bind(this)
        this.findPrice = this.findPrice.bind(this)
        this.addToCart = this.addToCart.bind(this)
        this.setSelectionFromUrl = this.setSelectionFromUrl.bind(this)
        this.changeQuantity = this.changeQuantity.bind(this)
        this.state = {
            data: {
                searchStr: '',
                showAll: false,
                selectedRemedy: null,
                selectedSize: null,
                currentPrice: null,
                currentWcId: null,
                selectedRemedyName: null,
                quantity: 1
            }
        }
    }

    componentDidMount() {
        const { products, loading } = this.props
        // console.log('Gemmotherapy Display Loaded!')
        // console.log(matchElement.path.split(':')[0])
        // this.props.match.params
        // console.log(this.props.match.params)
        if (
            this.props.match.params.remedyCode != null &&
            !products.loading &&
            !loading
        ) {
            this.setSelectionFromUrl()
        }
    }

    changeQuantity = quantity =>
        this.setState(oldState => ({ data: { ...oldState.data, quantity } }))

    setSelectionFromUrl = () => {
        const { oligotherapy } = this.props

        // Still using one letter keys to shorten url
        const RawUrlData = JSURL.parse(this.props.match.params.remedyCode)

        const UrlData = {
            selectedRemedy: RawUrlData.a
        }

        const activeRemedy = oligotherapy.find(element => {
            return UrlData.selectedRemedy === element.id
        })

        // console.log(activeRemedy)

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                selectedRemedy: activeRemedy.id,
                activeRemedy
                // selectedRemedyName: activeRemedy.nm
            }
        })
    }

    findPrice = size => {
        const { products } = this.props.products
        const product = products.find(element => {
            return element.slug === `single-oligotherapy-${size}`
        })

        if (product)
            return {
                id: product.id,
                price: parseFloat(Math.round(product.price * 100) / 100)
            }
    }

    onChange = e =>
        this.setState({
            data: {
                ...this.state.data,
                [e.target.name]: e.target.value
            },
            errors: { ...this.state.errors, [e.target.name]: false }
        })

    toggleShowAll = () => {
        if (this.state.data.showAll)
            this.setState({
                ...this.state,
                data: { ...this.state.data, showAll: false }
            })
        else
            this.setState({
                ...this.state,
                data: { ...this.state.data, showAll: true }
            })
    }

    setRemedy = id => {
        const { oligotherapy } = this.props

        // console.log(categoryRemedies)
        const activeRemedy = oligotherapy.find(element => {
            return element.id === id
        })

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                selectedRemedy: id,
                activeRemedy
                // selectedRemedyName: activeRemedy.fr
            }
        })
    }

    setSize = size => {
        const product = this.findPrice(size)
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                selectedSize: size,
                currentPrice: product.price,
                currentWcId: product.id
            }
        })
    }

    unSetRemedy = () => {
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                selectedRemedy: null,
                searchStr: '',
                selectedSize: null,
                selectedRemedyName: null
            }
        })
    }

    addToCart = () => {
        const {
            currentWcId,
            currentPrice,
            selectedSize,
            activeRemedy,
            quantity
        } = this.state.data

        const { addToCart } = this.props
        const newProduct = {
            WcId: currentWcId,
            price: currentPrice,
            name: `${activeRemedy.fr} ${activeRemedy.mg} mg/l`,
            icon: 'package_bottle',
            info: { size: selectedSize },
            quantity
        }
        addToCart(newProduct)
    }

    render() {
        const { apiElement, loading, products, oligotherapy } = this.props

        const {
            searchStr,
            showAll,
            selectedRemedy,
            selectedSize,
            currentPrice,
            activeRemedy,
            quantity
        } = this.state.data // eslint-disable-line

        const { changeQuantity } = this

        const notReady = loading || products.loading

        return (
            <div className="remedy_display_wrapper">
                <div className="remedy_display">
                    <h2>{apiElement.title}</h2>
                    <div
                        className={`icon icon_${apiElement.icon} icon_background`}
                    />
                    <input
                        type="text"
                        name="searchStr"
                        className="searchStr"
                        value={
                            selectedRemedy
                                ? `${activeRemedy.fr} ${activeRemedy.mg} mg/l`
                                : searchStr
                        }
                        onChange={
                            selectedRemedy ? this.unSetRemedy : this.onChange
                        }
                        onClick={selectedRemedy ? this.unSetRemedy : undefined}
                        placeholder={
                            notReady
                                ? _t('Loading...', 'ui')
                                : _t('Search Oligotherapy...', 'ui')
                        }
                        readOnly={notReady || products.loading}
                    />
                    {!notReady && !selectedRemedy && (
                        <button
                            type="button"
                            onClick={notReady ? null : this.toggleShowAll}
                            className="show_all link"
                        >
                            {showAll
                                ? _t('Hide all {nr} remedies', 'ui', {
                                      nr: oligotherapy.length
                                  })
                                : _t('Show all {nr} remedies', 'ui', {
                                      nr: oligotherapy.length
                                  })}
                        </button>
                    )}
                    <div className="remedies">
                        {!notReady &&
                            oligotherapy.map(element => {
                                const index = element.fr
                                    .toLowerCase()
                                    .indexOf(searchStr.toLowerCase())
                                if (
                                    ((index !== -1 && searchStr !== '') ||
                                        (showAll && searchStr === '')) &&
                                    !selectedRemedy
                                ) {
                                    const { id, fr, mg } = element
                                    return (
                                        <button
                                            type="button"
                                            className="remedy"
                                            style={{ margin: '0px' }}
                                            key={shortid.generate()}
                                            onClick={this.setRemedy.bind(
                                                this,
                                                id
                                            )}
                                        >
                                            {fr} {mg} mg/l
                                        </button>
                                    )
                                }
                                return null
                            })}
                    </div>
                    {!notReady && selectedRemedy && (
                        <div className="size_selector">
                            <SelectionButton
                                attribute={selectedSize}
                                method={this.setSize}
                                value="60ml"
                                icon="package_bottle"
                            />
                            <SelectionButton
                                attribute={selectedSize}
                                method={this.setSize}
                                value="150ml"
                                icon="package_bottle"
                            />
                            <SelectionButton
                                attribute={selectedSize}
                                method={this.setSize}
                                value="250ml"
                                icon="package_bottle"
                            />
                        </div>
                    )}
                    {!notReady && selectedRemedy && selectedSize && (
                        <div className="add_to_cart">
                            <span className="price">
                                {formatPrice(currentPrice)}
                            </span>
                            <QuantityField
                                onChange={changeQuantity}
                                quantity={quantity}
                            />
                            <button
                                type="button"
                                className="buy highlight"
                                onClick={this.addToCart}
                            >
                                {_t('Add to Cart', 'ui')}
                            </button>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

// OligotherapyDisplay.defaultProps = {
//  apiElement: {}
// }
// OligotherapyDisplay.propTypes = {
//  apiElement: PropTypes.object // eslint-disable-line
// }

const mapStateToProps = state => ({
    loading: state.singleRemedies.oligotherapyLoading,
    oligotherapy: state.singleRemedies.oligotherapy,
    products: state.products
})

export default connect(mapStateToProps, { addToCart })(
    withRouter(OligotherapyDisplay)
)
