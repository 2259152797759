import React from 'react'
// import PropTypes from 'prop-types'
import shortid from 'shortid'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import JSURL from 'jsurl'

import formatPrice from '../../utils/formatPrice'
import _t from '../../utils/translate'
import QuantityField from '../../utils/QuantityField'
import SelectionButton from '../SelectionButton'

import { addToCart } from '../../actions/cartActions'

class PhytotherapyDisplay extends React.Component {
    constructor(props) {
        super(props)
        this.onChange = this.onChange.bind(this)
        this.findPrice = this.findPrice.bind(this)
        this.addToCart = this.addToCart.bind(this)
        this.setSelectionFromUrl = this.setSelectionFromUrl.bind(this)
        this.changeQuantity = this.changeQuantity.bind(this)

        this.state = {
            data: {
                searchStr: '',
                showAll: false,
                selectedRemedy: null,
                selectedSize: null,
                currentPrice: null,
                currentWcId: null,
                selectedRemedyName: null,
                quantity: 1
            }
        }
    }

    componentDidMount() {
        const { products, singleRemediesLoading } = this.props
        // console.log('Gemmotherapy Display Loaded!')
        // console.log(matchElement.path.split(':')[0])
        // this.props.match.params
        // console.log(this.props.match.params)
        if (
            this.props.match.params.remedyCode != null &&
            !products.loading &&
            !singleRemediesLoading
        ) {
            this.setSelectionFromUrl()
        }
    }

    changeQuantity = quantity =>
        this.setState(oldState => ({ data: { ...oldState.data, quantity } }))

    setSelectionFromUrl = () => {
        const { phytotherapy } = this.props

        // Still using one letter keys to shorten url
        const RawUrlData = JSURL.parse(this.props.match.params.remedyCode)

        const UrlData = {
            selectedRemedy: RawUrlData.a
        }

        const activeRemedy = phytotherapy.find(element => {
            return UrlData.selectedRemedy === element.id
        })

        // console.log(activeRemedy)

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                selectedRemedy: activeRemedy.id,
                activeRemedy
                // selectedRemedyName: activeRemedy.nm
            }
        })
    }

    findPrice = size => {
        const { products } = this.props.products
        const product = products.find(element => {
            return element.slug === `single-phytotherapy-${size}`
        })

        if (product)
            return {
                id: product.id,
                price: parseFloat(Math.round(product.price * 100) / 100)
            }
    }

    onChange = e =>
        this.setState({
            data: {
                ...this.state.data,
                [e.target.name]: e.target.value
            },
            errors: { ...this.state.errors, [e.target.name]: false }
        })

    toggleShowAll = () => {
        if (this.state.data.showAll)
            this.setState({
                ...this.state,
                data: { ...this.state.data, showAll: false }
            })
        else
            this.setState({
                ...this.state,
                data: { ...this.state.data, showAll: true }
            })
    }

    setRemedy = id => {
        const { phytotherapy } = this.props

        // console.log(categoryRemedies)
        const activeRemedy = phytotherapy.find(element => {
            return element.id === id
        })

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                selectedRemedy: id,
                activeRemedy
                // selectedRemedyName: activeRemedy.nm
            }
        })
    }

    setSize = size => {
        const product = this.findPrice(size)
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                selectedSize: size,
                currentPrice: product.price,
                currentWcId: product.id
            }
        })
    }

    unSetRemedy = () => {
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                selectedRemedy: null,
                searchStr: '',
                selectedSize: null,
                selectedRemedyName: null
            }
        })
    }

    addToCart = () => {
        const {
            currentWcId,
            currentPrice,
            selectedSize,
            activeRemedy,
            quantity
        } = this.state.data

        const { addToCart } = this.props
        const newProduct = {
            WcId: currentWcId,
            price: currentPrice,
            name: activeRemedy.nm,
            icon: 'package_bottle',
            info: { size: `${selectedSize}` },
            quantity
        }
        addToCart(newProduct)
    }

    render() {
        const { apiElement, loading, products, phytotherapy } = this.props

        const {
            searchStr,
            showAll,
            selectedRemedy,
            selectedSize,
            currentPrice,
            activeRemedy,
            quantity
        } = this.state.data // eslint-disable-line

        const { changeQuantity } = this

        return (
            <div className="remedy_display_wrapper">
                <div className="remedy_display">
                    <h2>{apiElement.title}</h2>
                    <div
                        className={`icon icon_${apiElement.icon} icon_background`}
                    />
                    <input
                        type="text"
                        name="searchStr"
                        className="searchStr"
                        value={selectedRemedy ? activeRemedy.nm : searchStr}
                        onChange={
                            selectedRemedy ? this.unSetRemedy : this.onChange
                        }
                        onClick={selectedRemedy ? this.unSetRemedy : undefined}
                        placeholder={
                            loading
                                ? _t('Loading...', 'general')
                                : _t('Search Phytotherapy...', 'ui')
                        }
                        readOnly={loading || products.loading}
                    />
                    {!loading && !selectedRemedy && (
                        <button
                            type="button"
                            onClick={this.toggleShowAll}
                            className="show_all link"
                        >
                            {showAll
                                ? _t('Hide all {nr} remedies', 'ui', {
                                      nr: phytotherapy.length
                                  })
                                : _t('Show all {nr} remedies', 'ui', {
                                      nr: phytotherapy.length
                                  })}
                        </button>
                    )}
                    <div className="remedies">
                        {!loading &&
                            phytotherapy.map(element => {
                                const index = element.nm
                                    .toLowerCase()
                                    .indexOf(searchStr.toLowerCase())
                                if (
                                    ((index !== -1 && searchStr !== '') ||
                                        (showAll && searchStr === '')) &&
                                    !selectedRemedy
                                ) {
                                    const { id, nm } = element
                                    return (
                                        <button
                                            type="button"
                                            className="remedy"
                                            style={{ margin: '0px' }}
                                            key={shortid.generate()}
                                            onClick={this.setRemedy.bind(
                                                this,
                                                id
                                            )}
                                        >
                                            {nm}
                                        </button>
                                    )
                                }
                                return null
                            })}
                    </div>
                    {!loading && selectedRemedy && (
                        <div className="size_selector">
                            <SelectionButton
                                attribute={selectedSize}
                                method={this.setSize}
                                value="30ml"
                                icon="package_bottle"
                            />
                            <SelectionButton
                                attribute={selectedSize}
                                method={this.setSize}
                                value="60ml"
                                icon="package_bottle"
                            />
                            <SelectionButton
                                attribute={selectedSize}
                                method={this.setSize}
                                value="125ml"
                                icon="package_bottle"
                            />
                            <SelectionButton
                                attribute={selectedSize}
                                method={this.setSize}
                                value="250ml"
                                icon="package_bottle"
                            />
                        </div>
                    )}
                    {!loading && selectedRemedy && selectedSize && (
                        <div className="add_to_cart">
                            <span className="price">
                                {formatPrice(currentPrice)}
                            </span>
                            <QuantityField
                                onChange={changeQuantity}
                                quantity={quantity}
                            />
                            <button
                                type="button"
                                className="buy highlight"
                                onClick={this.addToCart}
                            >
                                {_t('Add to Cart', 'ui')}
                            </button>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

// PhytotherapyDisplay.defaultProps = {
//  apiElement: {}
// }
// PhytotherapyDisplay.propTypes = {
//  apiElement: PropTypes.object // eslint-disable-line
// }

const mapStateToProps = state => ({
    loading: state.singleRemedies.phytotherapyLoading,
    phytotherapy: state.singleRemedies.phytotherapy,
    products: state.products
})

export default connect(mapStateToProps, { addToCart })(
    withRouter(PhytotherapyDisplay)
)
