import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import base64url from 'base64url'
import { Helmet } from 'react-helmet'
import $ from 'jquery'
import LogRocket from 'logrocket'
import { CookieBanner } from '@palmabit/react-cookie-law'
import ReactGA from 'react-ga'

import Settings from './Settings'
import _t from './utils/translate'
// import Intercom from 'react-intercom'

// import Settings from './Settings'
import { loadLanguage } from './actions/pagesActions'
import { loadProducts } from './actions/productsActions'
import {
  loadSingleRemedies,
  loadSchussler,
  loadGemmotherapy,
  loadPhytotherapy,
  loadOligotherapy,
} from './actions/singleRemediesActions'
import { loadBrowserLanguages } from './actions/browserLanguagesActions'
import { showSearch } from './actions/uiActions'
import { loadOptions } from './actions/optionsActions'
import { loadPosts } from './actions/postsActions'
import { showCompleteResetPasswordPopup } from './actions/popupActions'
import { checkToken, loadUserData } from './actions/userActions'

import setAuthorizationHeader from './utils/setAuthorizationHeader'
import { isWhiteSpaceLike } from 'typescript'

// import { SET_LOADING } from './actions/types'
// import { store } from './store/store'

class AppWrapper extends Component {
  constructor(props) {
    super(props)
    this.onKeyDown = this.onKeyDown.bind(this)
  }

  state = { cookiesAccepted: false }

  componentDidMount() {
    document.body.addEventListener('keydown', this.onKeyDown)

    const {
      redux,
      loadLanguage,
      loadSingleRemedies,
      loadProducts,
      loadSchussler,
      loadGemmotherapy,
      loadPhytotherapy,
      loadOligotherapy,
      // loadOptions,
      location: { hash },
      language,
      showCompleteResetPasswordPopup,
      checkToken,
      loadUserData,
    } = this.props

    this.loadUserLikeLanguage(language)

    const showResetPassword = hash.slice(1, 15) === 'reset_password'
    console.log('showResetPassword')
    console.log(showResetPassword)

    if (showResetPassword) {
      const resetPasswordTokenParts = base64url
        .decode(hash.slice(15))
        .split('&')
      // console.log(resetPasswordTokenParts)
      const resetPasswordToken = resetPasswordTokenParts[0]
      // const name = resetPasswordTokenParts[1]
      // const email = resetPasswordTokenParts[2]
      // this.setState({ isPopup: true })
      showCompleteResetPasswordPopup(resetPasswordToken)
      console.log('SHOW RESET PASSWORD')
      // console.log('token')
    }

    if (redux?.user?.loggedIn) {
      checkToken(redux?.user?.token)
      loadUserData()
    }

    if (redux.pages.loading) {
      loadLanguage(null, true)
    }
    if (redux?.user?.token) {
      setAuthorizationHeader(redux.user.token)
    }

    if (!window.location.href.includes('localhost') && redux?.user?.login) {
      LogRocket.identify(redux?.user?.login)
    }
    if (redux.singleRemedies.loading) {
      loadSingleRemedies()
    }
    if (redux.products.loading) {
      loadProducts()
    }

    if (redux.singleRemedies.schusslerLoading) {
      loadSchussler()
    }
    if (redux.singleRemedies.gemmotherapyLoading) {
      loadGemmotherapy()
    }
    if (redux.singleRemedies.phytotherapyLoading) {
      loadPhytotherapy()
    }
    if (redux.singleRemedies.oligotherapyLoading) {
      loadOligotherapy()
    }
    // the only action here that doesnt need dispatch
    loadOptions()
    loadPosts()
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      overlayVisible,
      messageVisible,
      searchVisible,
      language,
    } = this.props
    if (
      prevProps.overlayVisible !== overlayVisible ||
      prevProps.messageVisible !== messageVisible ||
      prevProps.searchVisible !== searchVisible
    ) {
      if (overlayVisible || messageVisible || searchVisible)
        $('html').css('overflow', 'hidden')
      else $('html').removeAttr('style')
    }

    if (prevProps.language !== language) this.loadUserLikeLanguage(language)

    if (!prevState.cookiesAccepted && this.state.cookiesAccepted) {
      if (!window.location.href.includes('localhost')) {
        console.log('Not local testing. Start Logging.')
        LogRocket.init('yius8m/sn-prod-with-shop', {
          mergeIframes: true,
          release:
            process.env.REACT_APP_GIT_SHA && process.env.REACT_APP_GIT_COUNT
              ? `${process.env.REACT_APP_GIT_BRANCH} // Nr: ${process.env.REACT_APP_GIT_COUNT} // ${process.env.REACT_APP_GIT_SHA}`
              : 'unknown',
        })
      } else {
        console.log("Local testing. Don't start logging.")
      }

      ReactGA.initialize('UA-140713645-1', {
        gaOptions: {
          // userId: 123
        },
      })
    }
  }

  componentWillUnmount() {
    document.body.removeEventListener('keydown', this.onKeyDown)
  }

  loadUserLikeLanguage = (language = 'en') => {
    const userLikeUrls = {
      en:
        'https://userlike-cdn-widgets.s3-eu-west-1.amazonaws.com/81356a52847d4c16a04a9c58f43bfffca82f6beb928f62d59b46b12fe680e897.js?v=1',
      de:
        'https://userlike-cdn-widgets.s3-eu-west-1.amazonaws.com/288e388cae9d4af2889b72e39b97b6033b9c93be67874dd993ba6260d38bbe49.js',
      fr:
        'https://userlike-cdn-widgets.s3-eu-west-1.amazonaws.com/5e8aa6bf0e94423490fec356a58e81eac504225fbe4448fea84435f00d7d1125.js',
      it:
        'https://userlike-cdn-widgets.s3-eu-west-1.amazonaws.com/d94a0e930458446f910c120c28a4268d65f94f1b9039459c95c25c7c3ec70779.js',
    }

    if (!userLikeUrls?.[language]) {
      console.error(`Couldn't find UserLike Url for ${language}`)
      return null
    }

    // Remove previous Userlike Instances
    document.querySelectorAll("[id^='userlike']").forEach(function(a) {
      a.remove()
    })
    document.querySelectorAll('.userlikescript').forEach(function(a) {
      a.remove()
    })

    const chat = document.createElement('script')
    chat.src = userLikeUrls?.[language]
    chat.async = true
    chat.className = `userlikescript ${language}`

    document.body.appendChild(chat)

    setTimeout(() => {
      document.querySelectorAll("[id^='userlike']").forEach(function(a) {
        a.remove()
      })
    })
  }

  onKeyDown(e) {
    const { showSearch } = this.props
    if (e.target.nodeName === 'BODY')
      if (e.key === 'f') {
        // This is the place for application wide Keyboard Shortcuts
        showSearch()
        console.log('Show search')
        e.preventDefault()
      }
    // console.log(e.key)
  }

  render() {
    const {
      children,
      pagesLoading,
      overlayVisible,
      messageVisible,
      language,
      options,
      location,
    } = this.props

    // console.log(location)

    const { cookiesAccepted } = this.state

    const keywords = options.loading
      ? null
      : options?.data?.[language]?.seo?.global_keywords

    return (
      <>
        <Helmet htmlAttributes={{ lang: language }}>
          {keywords && <meta name="keywords" content={keywords} />}
        </Helmet>
        <div
          className={
            pagesLoading || overlayVisible || messageVisible
              ? 'app_wrapper loading'
              : 'app_wrapper'
          }
          key={`app_wrapper_${language}`}
        >
          {children}
        </div>
        {/* // Only show overlay if cookies are not accepted yet and user is not viewing the privacy policy. */}
        <div
          className={`cookie_overlay ${
            !cookiesAccepted &&
            !location?.pathname?.includes(_t('privacy-policy', 'routes'))
              ? 'active'
              : ''
          }`}
        />
        {/*_t(
            "We use essential cookies to make our site work. We and partners also use cookies to personalise your experience and measure our site's performance."
          )*/}
        <CookieBanner
          message={
            options?.data?.[language]?.privacy_policy_popup?.popup_text || ''
          }
          wholeDomain={true}
          policyLink={`/${language}/${_t('privacy-policy', 'routes')}`}
          privacyPolicyLinkText={
            options?.data?.[language]?.privacy_policy_popup
              ?.privacy_policy_link_text || 'Privacy Policy'
          }
          necessaryOptionText={
            options?.data?.[language]?.privacy_policy_popup?.necessary_label ||
            'Necessary'
          }
          preferencesOptionText={
            options?.data?.[language]?.privacy_policy_popup
              ?.preferences_label || 'Preferences'
          }
          statisticsOptionText={
            options?.data?.[language]?.privacy_policy_popup?.statistics_label ||
            'Statistics'
          }
          marketingOptionText={
            options?.data?.[language]?.privacy_policy_popup?.marketing_label ||
            'Marketing'
          }
          acceptButtonText={
            options?.data?.[language]?.privacy_policy_popup
              ?.accept_all_button_text || 'Accept All'
          }
          declineButtonText={_t('Decline')}
          managePreferencesButtonText={
            options?.data?.[language]?.privacy_policy_popup
              ?.manage_my_cookies_button_text || 'Manage my Cookies'
          }
          savePreferencesButtonText={
            options?.data?.[language]?.privacy_policy_popup
              ?.save_and_close_button_text || 'Save and Close'
          }
          onAccept={() => {
            // console.log('onAccept')
            if (!cookiesAccepted) this.setState({ cookiesAccepted: true })
          }}
          onAcceptPreferences={() => {}}
          onAcceptStatistics={() => {}}
          onAcceptMarketing={() => {}}
          styles={{
            dialog: {
              position: 'fixed',
              bottom: 0,
              width: '100%',
              background: Settings.primaryColor,
              color: 'white',
              zIndex: 10000,
            },
            container: {},
            buttonWrapper: {},
            selectPane: {},
            message: { color: 'white' },
            policy: {
              marginLeft: 0,
              color: 'white',
              textDecoration: 'underline',
            },
            optionLabel: {
              color: 'white',
              lineHeight: '25px',
            },
            button: {},
          }}
        />
      </>
    )
  }
}

// <Intercom
//     appID={Settings.IntercomAppId}
//     {...user}
//     language_override="de"
// />

const mapStatetoProps = (state) => ({
  pagesLoading: state.pages.loading,
  overlayVisible: state.ui.overlayVisible,
  messageVisible: state.ui.messageVisible,
  searchVisible: state.ui.searchVisible,
  language: state.ui.language,
  redux: state,
  options: state.options,
  // user: state.user
})

AppWrapper.defaultProps = {
  children: '',
}

AppWrapper.propTypes = {
  children: PropTypes.array, // eslint-disable-line
  pagesLoading: PropTypes.bool.isRequired,
  overlayVisible: PropTypes.bool.isRequired,
  messageVisible: PropTypes.bool.isRequired,
  searchVisible: PropTypes.bool.isRequired,
  language: PropTypes.string.isRequired,
}

export default connect(mapStatetoProps, {
  loadLanguage,
  loadProducts,
  loadSingleRemedies,
  loadBrowserLanguages,
  loadSchussler,
  loadGemmotherapy,
  loadPhytotherapy,
  loadOligotherapy,
  // loadOptions,
  showSearch,
  showCompleteResetPasswordPopup,
  checkToken,
  loadUserData,
})(withRouter(AppWrapper))
