//  ------------------------------------------------------------------------------------------------
//
//      This Array of the routes is for use in the search algorithm.
//      It is not used for the generation of the actual routes.
//
//  ------------------------------------------------------------------------------------------------

const RoutesForSearch = [
    { link: '/', title: 'Home', slug: 'landing' },
    // ----------------  SHOP  ---------------- //
    {
        link: '/shop/homeopathy',
        url: ['shop', 'homeopathy'],
        title: 'Shop Homeopathy',
        slug: 'shop_homeopathy'
    },
    {
        link: '/shop/homeopathy/complexes',
        url: ['shop', 'homeopathy', 'complexes'],
        title: 'Shop Homepathy Complexes',
        slug: 'shop_homeopathy_complexes'
    },
    {
        link: '/shop/homeopathy/remedies',
        url: ['shop', 'homeopathy', 'remedies'],
        title: 'Shop Homeopathy Remedies',
        slug: 'shop_homeopathy_remedies'
    },
    {
        link: '/shop/gemmotherapy',
        url: ['shop', 'gemmotherapy'],
        title: 'Shop Gemmotherapy',
        slug: 'shop_gemmotherapy'
    },
    {
        link: '/shop/phytotherapy',
        url: ['shop', 'phytotherapy'],
        title: 'Shop Phytotherapy',
        slug: 'shop_phytotherapy'
    },
    {
        link: '/shop/oligotherapy',
        url: ['shop', 'oligotherapy'],
        title: 'Shop Oligotherapy',
        slug: 'shop_oligotherapy'
    },
    {
        link: '/shop/schussler_salts',
        url: ['shop', 'schussler_salts'],
        title: 'Shop Schussler Salts',
        slug: 'shop_schussler_salts'
    },
    {
        link: '/shop/food_supplements',
        url: ['shop', 'food_supplements'],
        title: 'Shop Food Supplements',
        slug: 'shop_food_supplements'
    },
    {
        link: '/shop/make_your_own',
        url: ['shop', 'make_your_own'],
        title: 'Shop Make your Own',
        slug: 'shop_make_your_own'
    },
    {
        link: '/shop/seasonal',
        url: ['shop', 'homeopathy', 'seasonal'],
        title: 'Shop Seasonal',
        slug: 'shop_seasonal_products'
    },
    // ----------------  MEDICINE  ---------------- //
    {
        link: '/medicine/homeopathy',
        url: ['medicine', 'homeopathy'],
        title: 'Medicine Homeopathy',
        slug: 'medicine_homeopathy'
    },
    {
        link: '/medicine/gemmotherapy',
        url: ['medicine', 'gemmotherapy'],
        title: 'Medicine Gemmotherapy',
        slug: 'medicine_gemmotherapy'
    },
    {
        link: '/medicine/phytotherapy',
        url: ['medicine', 'phytotherapy'],
        title: 'Medicine Phytotherapy',
        slug: 'medicine_phytotherapy'
    },
    {
        link: '/medicine/oligotherapy',
        url: ['medicine', 'oligotherapy'],
        title: 'Medicine Oligotherapy',
        slug: 'medicine_oligotherapy'
    },
    {
        link: '/medicine/schussler_salts',
        url: ['medicine', 'schussler_salts'],
        title: 'Medicine Schussler Salts',
        slug: 'medicine_schussler_salts'
    },
    {
        link: '/medicine/food_supplements',
        url: ['medicine', 'food_supplements'],
        title: 'Medicine Food Supplements',
        slug: 'medicine_food_supplements'
    },
    // ----------------  COMPANY  ---------------- //
    {
        link: '/company/story',
        url: ['company', 'story'],
        title: 'Company Story',
        slug: 'company_story'
    },
    {
        link: '/company/lab',
        url: ['company', 'lab'],
        title: 'Company Lab',
        slug: 'company_lab'
    },
    {
        link: '/company/values',
        url: ['company', 'values'],
        title: 'Company Values',
        slug: 'company_values'
    },
    {
        link: '/company/quality',
        url: ['company', 'quality'],
        title: 'Company Quality',
        slug: 'company_quality'
    },
    {
        link: '/company/team',
        url: ['company', 'team'],
        title: 'Company Team',
        slug: 'company_team'
    },
    // ----------------  SCIENCE  ---------------- //
    {
        link: '/resources',
        url: ['resources'],
        title: 'Resources',
        slug: 'resources'
    },
    // ----------------  HEALTH  ---------------- //
    {
        link: '/health',
        url: ['health'],
        title: 'Health',
        slug: 'health'
    }
]

export default RoutesForSearch
