import React from 'react'
import { Route } from 'react-router-dom'

import SinglePageRenderer from '../SinglePageRenderer'
import SingleResourcesPage from './SingleResourcesPage'
import _t from '../../utils/translate'

const ResourcesPage = ({ language }) => (
	<>
		<Route
			exact
			path={`/${language}/${_t('resources', 'routes')}`}
			component={() => (
				<SinglePageRenderer
					paddingTop={70}
					title="SN - Resources"
					pageId="resources"
				/>
			)}
		/>
		<Route
			path={`/${language}/${_t('resources', 'routes')}/:topic`}
			component={SingleResourcesPage}
		/>
	</>
)

export default ResourcesPage
