import React from 'react'
import { Route, Switch } from 'react-router-dom'

import _t from './utils/translate'

import LandingPage from './components/pages/LandingPage'
import ShopPage from './components/pages/ShopPage'
import CompanyPage from './components/pages/CompanyPage'
import MedicinePage from './components/pages/MedicinePage'
import HealthPage from './components/pages/HealthPage'
import ResourcesPage from './components/pages/ResourcesPage'
import AccountPage from './components/pages/AccountPage'
import SignupPage from './components/pages/SignupPage'
import ContactPage from './components/pages/ContactPage'
import SitemapPage from './components/pages/SitemapPage'
import CheckoutPage from './components/pages/CheckoutPage'
import ImprintPage from './components/pages/ImprintPage'
import PrivacyPolicyPage from './components/pages/PrivacyPolicyPage'
import NewsPage from './components/pages/NewsPage'
import NotFoundPage from './components/pages/NotFoundPage'
import SinglePageRenderer from './components/SinglePageRenderer'

const LanguageRouter = ({ language }) => {
  return (
    <Switch>
      <Route
        path={`/${language}/afbe94cdbe69a93efabc9f1325fc7dff`}
        exact
        component={() => <SinglePageRenderer pageId="hidden-page" />}
      />
      <Route
        path={`/${language}/c3ff7c66b5c6127379fd297c76ffeccd`}
        exact
        component={() => (
          <SinglePageRenderer pageId="c3ff7c66b5c6127379fd297c76ffeccd" />
        )}
      />
      <Route
        path={`/${language}/`}
        exact
        component={() => <LandingPage language={language} />}
      />
      <Route
        path={`/${language}/${_t('login', 'routes')}/`}
        exact
        component={LandingPage}
      />
      <Route
        path={`/${language}/${_t('shop', 'routes')}/`}
        component={() => <ShopPage language={language} />}
      />
      <Route
        path={`/${language}/${_t('company', 'routes')}/`}
        component={() => <CompanyPage language={language} />}
      />
      <Route
        path={`/${language}/${_t('medicine', 'routes')}`}
        component={() => <MedicinePage language={language} />}
      />
      <Route
        path={`/${language}/${_t('health', 'routes')}`}
        component={() => <HealthPage language={language} />}
      />
      <Route
        path={`/${language}/${_t('resources', 'routes')}/`}
        component={() => <ResourcesPage language={language} />}
      />
      <Route
        path={`/${language}/${_t('account', 'routes')}/`}
        component={() => <AccountPage language={language} />}
      />
      <Route
        path={`/${language}/${_t('signup', 'routes')}/`}
        component={() => <SignupPage language={language} />}
      />
      <Route
        path={`/${language}/${_t('contact', 'routes')}/`}
        component={() => <ContactPage language={language} />}
      />
       <Route
        path={`/${language}/${_t('sitemap', 'routes')}/`}
        component={() => <SitemapPage language={language} />}
      />
      <Route
        path={`/${language}/${_t('checkout', 'routes')}/`}
        component={() => <CheckoutPage language={language} />}
      />
      <Route
        path={`/${language}/${_t('imprint', 'routes')}/`}
        component={() => <ImprintPage language={language} />}
      />
      <Route
        path={`/${language}/${_t('privacy-policy', 'routes')}/`}
        component={() => <PrivacyPolicyPage language={language} />}
      />
      <Route
        path={`/${language}/${_t('news', 'routes')}/`}
        component={() => <NewsPage language={language} />}
      />
      <Route component={() => <NotFoundPage language={language} />} />
    </Switch>
  )
}

export default LanguageRouter
