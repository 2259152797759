export const fr = {
  schussler_salts: [
    ['pl_6', 'Comprimés 6DH'],
    ['pl_12', 'Comprimés 12DH'],
    ['p_6', 'Trituration 6DH'],
    ['p_12', 'Trituration 12DH'],
    ['p', 'Trituration'],
  ],
  resources: [['Other Resources', 'Autres ressources ']],
  professions: [
    ['pharmacist', 'Pharmacien'],
    ['therapeut', 'Thérapeute'],
    [
      'You can only sign up as a professional.',
      "Vous ne pouvez vous inscrire qu'en tant que professionnel.",
    ],
    ['Please specify your Pharma Id.', 'Veuillez préciser votre Pharma Id.'],
  ],
  general: [
    [`Profession`, `Profession`],
    [`First and last name`, `Nom et prénom`],
    [
      `Name of pharmacy and customer number`,
      `Nom de la pharmacie / droguerie et numéro client le cas échéant`,
    ],
    [`Phone number`, `Numéro de téléphone`],
    [`FPH or GLN number for credits`, `Numéro FPH ou GLN pour les crédits`],
    [`Message`, `Message`],
    [
      `Unfortunately you cannot order in the shop. Please use the order form that you can download on your account page.`,
      `Malheureusement, vous ne pouvez pas commander dans la boutique. Veuillez utiliser le formulaire de commande que vous pouvez télécharger sur la page de votre compte.`,
    ],
    [
      `Unfortunately you cannot order in the shop. Please contact us to enable this function.`,
      `Malheureusement, vous ne pouvez pas commander dans la boutique. Veuillez nous contacter pour activer cette fonction.`,
    ],
    [
      `Please log in or register to buy products.`,
      `Veuillez vous connecter ou vous inscrire pour acheter des produits.`,
    ],
    [
      `The login information you provided is wrong.`,
      `Les données de connexion ne sont pas correctes. Veuillez réessayer.`,
    ],
    [
      `Thank you for signing up. We'll inform you once your account has been activated.`,
      `Merci de vous être inscrit. Nous vous informerons dès que votre compte aura été activé.`,
    ],
    [`Sign Up`, `S'inscrire`],
    [
      `Emails are not the same`,
      `Les adresses électroniques ne sont pas les mêmes`,
    ],
    ['Search {type}...', 'Rechercher dans {type}...'],
    ['add', 'ajouter'],
    ['Account', 'Compte'],
    ['Back to News', 'Retour aux news'],
    ['Back to Resources', 'Retour aux ressources'],
    ['Back', 'Retour'],
    ['or', 'ou'],
    ['OTC:', 'OTC:'],
    ['Change File', 'Modifier le fichier'],
    ['Substances', 'Substances'],
    ['Learn More', 'En savoir plus'],
    ['Loading...', 'Chargement en cours...'],
    [`Edit`, `Modifier`],
    [`Shipping Address`, `Adresse de livraison`],
    [`Billing Address`, `Adresse de facturation`],
    [`View Item`, `Voir produit`],
    [`Back to Food Supplements`, `Retour aux compléments alimentaires`],
    [`Back to Schussler Salts`, `Retour aux Sels de Schüssler`],
    [`Back to OTCs`, `Retour aux OTCs`],
    [`Read more`, `Lire plus`],

    [`Back to Health`, `Retour à Santé`],
    [`Phone`, `Téléphone`],
    [`Email`, `Email`],
    [`Password`, `Mot de passe`],
    [`Save`, `Sauvegarder`],
    [`Loading...`, `Chargement..`],
    [`Shop`, `Magasin`],
    [`Close`, `Fermer`],
    [`Delete`, `Supprimer`],
    [
      `Do you want to delete this substance?`,
      `Voulez-vous supprimer cette substance?`,
    ],
    [`Cancel`, `Annuler`],
    [
      `Granules are only available if there is at least one Homeopathy substance`,
      `Les granules sont uniquement disponibles s'il y a au moins une substance homéopathique`,
    ],
    [
      `Globuli are only available if there is at least one Homeopathy substance`,
      `Les globules sont uniquement disponibles s'il ya au moins une substance homéopathique`,
    ],
    [
      `Drops are only available up to 30CH`,
      `les gouttes existent jusqu'à 30CH`,
    ],
    ['Please enter your profession', 'Veuillez entrer votre profession'],
    [`Address 1`, `Adresse 1`],
    [`Shipping Address is different`, `L'adresse de livraison est différente`],
    [`State`, `Canton`],
    [`Postcode`, `Code postal`],
    [`Last Name`, `Nom`],
    [`Repeat Email`, `Répéter l'e-mail`],
    [`First Name`, `Prénom`],
    [`Sign up`, `S'enregister`],
    [`Choose File`, `Choisir un fichier`],
    [`Repeat Password`, `Répéter le mot de passe`],
    [`Company`, `Entreprise`],
    [`Address 2`, `Adresse 2`],
    [`City`, `Ville`],
    [`Certificate`, `Autorisation cantonale d’exercice de la profession / Diplôme`],
    [`General`, `Général`],
    [`substances`, `substances`],
    [`Remedies`, `remèdes`],
    [`Complexe`, `Complexe`],
    [
      `Please note, that by law we are not allowed to sell our products directly to end customers. Therefore Sign Up is only available to swiss therapists with practice authorization from the canton. This has to be proven by uploading a document that verifies you as a professional.`,
      `Notez que par la loi nous ne sommes pas autorisés à vendre aux clients privés. La commande en ligne sur l’e-shop n’est pas disponible pour les pharmacies. La vente de nos médicaments à des thérapeutes dépend de la réglementation en vigueur dans les différents cantons suisses. Merci de nous fournir votre autorisation cantonale d’exercice de la profession ainsi que votre diplôme.`,
    ],
    [`Topic`, `Thème`],
    [`Composition`, `Composition`],
    [`Posology`, `Posologie`],
    [`Back to Complexes`, `Retour aux complexes`],
    [`Indication`, `Indications `],
    [`Item has ben added to Cart!`, `Le produit a été ajouté à votre panier !`],
    [`Successfully logged out!`, `Déconnexion réussie !`],
    [`Successfully logged in!`, `Connexion réussie !`],
    [`Good Afternoon`, `Bonjour`],
    [`Good Morning`, `Bonjour`],
    [`Good Evening`, `Bonsoir`],
    [`Good Night`, `Bonne nuit`],
    [`Pages`, `Page`],
    [`Gemmotherapy`, `Gemmothérapie`],
    [`Phytotherapy`, `Phytothérapie`],
    [`Homeopathy`, `Homéopathie`],
    [`Oligotherapy`, `Oligothérapie`],
    [`Shipping Address is the same`, `L'adresse de livraison est la même`],
    [`Invalid email`, `Adresse email invalide`],
    [`Can't be blank`, `Ne peut être vide`],
    [
      `Your password has to be at least eight characters long`,
      `Le mot de passe doit comporter au min. 8 caractères`,
    ],
    [`Please select a certificate.`, `Veuillez choisir un certificat.`],
    [
      `There are errors in the form, please check and try again.`,
      `Il y a des erreurs dans le formulaire. Veuillez le contrôler et réessayer.`,
    ],
    [`Passwords are not the same`, `Les mots de passe sont différents`],
  ],
  health: [['Other Health Topics', 'Autres sujets de santé']],
  ui: [
    ['ok', 'ok'],
    ['Add to Cart', 'Ajouter au panier'],
    ['Search Phytotherapy...', 'Chercher...'],
    ['Search Gemmotherapy...', 'Chercher...'],
    ['Search Oligotherapy...', 'Chercher...'],
    ['Search Homeopathy...', 'Chercher...'],
    ['Hide all {nr} remedies', 'Masquer tous les {nr} remèdes'],
    ['Show all {nr} remedies', 'Voir les {nr} remèdes'],
    ['Loading...', 'Chargement...'],
  ],
  cart: [
    ['{percent}% Discount:', '{percent}% de réduction :'],
    ['Cart', 'Panier'],
    ['Your cart is empty.', 'Votre panier est vide.'],
    ['Total', 'Total'],
    ['Granules', 'Granules'],
  ],
  account: [
    [
      `To reset your password, please enter your email address.`,
      `Pour réinitialiser votre mot de passe, veuillez saisir votre adresse électronique.`,
    ],
    [
      `Ok, great! Let's set a new password:`,
      'Parfait ! Réinitialisons le mot de passe',
    ],
    ['Reset Password', 'Réinitialiser le mot de passe'],
    ['Repeat Password', 'Confirmer le mot de passe'],
    ['Customer', 'Client'],
    ['Set password', 'Définir le mot de passe'],
    ['Continue', 'Continuer'],
    ['Logout', 'Déconnexion'],
    [`Address Line 1`, `Adresse ligne 1`],
    [`Address Line 2`, `Adresse ligne 2`],
    [`City`, `Ville`],
    [`Postcode`, `Code postal`],
    [`State`, `Canton`],
    [`Country`, `Pays`],
    [`First Name`, `Prénom`],
    [`Company`, `Entreprise`],
    [`Last Name`, `Nom`],
    [`Old Password`, `Ancien mot de passe`],
    [`New Password`, `Nouveau mot de passe`],
    [`Repeat New Password`, `Répétition nouveau mot de passe`],
    [`Administrator`, `Administrateur`],
    [`Good Afternoon`, `Bonjour`],
    [`Change Status`, `Changement de statut`],
    [`You're registered as a`, `Vous êtes enregistré en tant que `],
    [`Back to Account`, `Retour au compte`],
    [`Account Orders`, `Commandes par compte `],
    [`Orders`, `Commandes `],
    [`Good Evening`, `Bonsoir`],
    [`Account Security`, `Sécurité du compte`],
    [`Security`, `Sécurité`],
    [`Account Addresses`, `Adresses du compte`],
    [`Addresses`, `Adresses`],
    [`Shipping Address`, `Adresse de livraison`],
    [`Billing Address`, `Adresse de facturation`],
    [`unknown`, `inconnu`],
    [`Email`, `Email`],
    [`Password`, `Mot de passe`],
    [`Forgot Password?`, `Mot de passe oublié ?`],
    [`Log In`, `Se connecter`],
    [`Sign Up`, `S'inscrire`],
  ],
  menu: [
    [`Cart`, `Panier`],
    [`Start`, `Maison`],
    [`Search`, `Recherche`],
    [`Health`, `Santé`],
    [`Food Supplements`, `Compléments alimentaires`],
    [`Homeopathy`, `Homéopathie`],
    [`Phytotherapy`, `Phytothérapie`],
    [`Oligotherapy`, `Oligothérapie`],
    [`Schussler Salts`, `Sels de Schüssler`],
    [`Gemmotherapy`, `Gemmothérapie`],
    [`Story`, `Histoire `],
    [`Values`, `Valeurs`],
    [`Quality`, `Qualité`],
    [`Team`, `Equipe`],
    [`Lab`, `Laboratoire`],
    [`Make your Own`, `Faites-le vous-même`],
    [`Your Orders`, `Votre commande`],
    [`Addresses`, `Adresses`],
    [`Medicine`, `Médecine`],
    [`Company`, `Entreprise`],
    ['Resources', 'Ressources'],
    [`Shop`, `Magasin`],
    [`Imprint`, `Impressum`],
    [`Contact`, `Contact`],
    [`Security`, `Sécurité`],
    [`Login / Register`, `Se connecter / S'inscrire`],
    [`Language`, `Language`],
    [`French`, `French`],
    [`SN Canada`, `SN Canada`],
    [`English`, `English`],
    [`German`, `German`],
    [`Italian`, `Italian`],
    [`News`, `News`],
    // [`Health`, `Santé`],
    // [`Food Supplements`, `Compléments alimentaires`],
    // [`Homeopathy`, `Homéopathie`],
    // [`Phytotherapy`, `Phytothérapie`],
    // [`Oligotherapy`, `Oligothérapie`],
    // [`Schussler Salts`, `Sels Schüssler`],
    // [`Gemmotherapy`, `Gemmothérapie`],
    // [`Story`, `Histoire`],
    // [`Values`, `Valeurs`],
    // [`Quality`, `Qualité`],
    // [`Team`, `Équipe`],
    // [`Lab`, `Laboratoire`],
    // // [`Make your Own`, `Produit ton propre remède`],
    // [`Make your Own`, `Mélanges`],
    // [`Your Orders`, `Test`],
    // [`Addresses`, `Test`],
    // [`Medicine`, `Médecine`],
    // [`Company`, `Compagnie`],
    // [`Science`, `Science`],
    // [`Shop`, `Shop`],
    // [`Imprint`, `Marque d'éditeur`],
    // [`Contact`, `Contact`],
  ],
  countries: [
    [`Kyrgyzstan`, `Kirghizistan`],
    [`Rwanda`, `Rwanda`],
    [`Equatorial Guinea`, `Guinée Equatoriale`],
    [`Marshall Islands`, `Iles Marshall`],
    [`Iraq`, `Irak`],
    [`Italy`, `Italie`],
    [`Ivory Coast`, `Côte d'Ivoire`],
    [`Georgia`, `Géorgie`],
    [`Germany`, `Allemagne`],
    [`Falkland Islands`, `les îles Falkland`],
    [`Guinea`, `Guinée `],
    [`Guyana`, `Guyane`],
    [`Sri Lanka`, `Sri Lanka`],
    [`Turks and Caicos Islands`, `îles Turques-et-Caïques`],
    [`Turkmenistan`, `Turkménistan`],
    [`Jamaica`, `Jamaique `],
    [`Tuvalu`, `Tuvalu`],
    [`Lebanon`, `Liban`],
    [`Latvia`, `Lettonie`],
    [`Lesotho`, `Lesotho`],
    [`Liberia`, `Libéria`],
    [`South Africa`, `Afrique du Sud`],
    [`Mongolia`, `Mongolie`],
    [`Montenegro`, `Monténegro`],
    [`Norway`, `Norvège`],
    [`France`, `France`],
    [`Romania`, `Roumanie`],
    [`Iran`, `Iran`],
    [`Saint Martin (Dutch part)`, `Saint Martin`],
    [`Peru`, `Pérou`],
    [`Saint Barthélemy`, `Saint Barthélémy`],
    [`Austria`, `Autriche`],
    [`Laos`, `Laos`],
    [`Antigua and Barbuda`, `Antigua-et-Barbuda`],
    [`Wallis and Futuna`, `Wallis et Futuna`],
    [`Bosnia and Herzegovina`, `Bosnie`],
    [`Tajikistan`, `Tadjikistan`],
    [`Myanmar`, `Myanmar`],
    [`Saint Lucia`, `Saint Lucie`],
    [`CuraÇao`, `Curaçao`],
    [`Saint Martin (French part)`, `Saint Martin`],
    [`Russia`, `Russie`],
    [`Serbia`, `Serbie`],
    [`Belau`, `Belau`],
    [`São Tomé and Príncipe`, `São Tomé and Príncipe`],
    [`Pakistan`, `Pakistan`],
    [`Australia`, `Australie`],
    [`Bahrain`, `Bahrain`],
    [`Senegal`, `Sénégal`],
    [`Trinidad and Tobago`, `Trinité-et-Tobago`],
    [`Namibia`, `Namibie`],
    [`Belarus`, `Biélorussie`],
    [`British Virgin Islands`, `Îles Vierges britanniques`],
    [`Congo (Brazzaville)`, `Congo`],
    [`Nauru`, `Nauru`],
    [`Barbados`, `Barbade`],
    [`Nicaragua`, `Nicaragua`],
    [`Papua New Guinea`, `Papouasie Nouvelle Guinée`],
    [`Lithuania`, `Lituanie`],
    [`Reunion`, `La Réunion`],
    [`Cuba`, `Cuba`],
    [`Afghanistan`, `Afghanistan`],
    [`Paraguay`, `Paraguay`],
    [`Finland`, `Finlande`],
    [`Bangladesh`, `Bangladesh`],
    [`Brazil`, `Brésil`],
    [`Eritrea`, `Érythrée`],
    [`Congo (Kinshasa)`, `Congo `],
    [`Kuwait`, `Kuweit`],
    [`Norfolk Island`, `l'ile de Norfolk`],
    [`Niger`, `Niger`],
    [`North Korea`, `Corée du Nord`],
    [`Oman`, `Oman`],
    [`South Sudan`, `Soudan du sud`],
    [`South Georgia/Sandwich Islands`, `Géorgie du Sud / Îles Sandwich`],
    [`United Arab Emirates`, `Emirats Arabes Unis`],
    [`Svalbard and Jan Mayen`, `Svalbard et Jan Mayen`],
    [`South Korea`, `Corée du Sud`],
    [`Western Samoa`, `Samoa occidentales`],
    [`Angola`, `Angola`],
    [`Aruba`, `Aruba`],
    [`Azerbaijan`, `Azerbaïdjan`],
    [`Bahamas`, `Bahamas`],
    [`Egypt`, `Egypte`],
    [`Bouvet Island`, `Ile Bouvet`],
    [`Heard Island and McDonald Islands`, `Îles Heard et McDonald`],
    [`Hong Kong`, `Hong kong`],
    [`Kenya`, `Kenia`],
    [`Haiti`, `Haiti`],
    [`Kazakhstan`, `Kazakhstan`],
    [`Libya`, `Libye`],
    [`Mayotte`, `Mayotte`],
    [`Saint Helena`, `Sainte-Hélène`],
    [`Martinique`, `Martinique`],
    [`Tonga`, `Tonga`],
    [`Faroe Islands`, `îles Féroé`],
    [`Mexico`, `Mexique`],
    [`Malaysia`, `Malaisie`],
    [`Cocos (Keeling) Islands`, `îles Cocos`],
    [`Palestinian Territory`, `Territoire Palestinien `],
    [`Panama`, `Panama`],
    [`Slovakia`, `Slovaquie`],
    [`Cameroon`, `Cameroun`],
    [`Estonia`, `Estonie`],
    [`Dominica`, `Dominique`],
    [`Czech Republic`, `République Tchèque `],
    [`Fiji`, `Fidji`],
    [`Guatemala`, `Guatemala`],
    [`India`, `Inde`],
    [`Indonesia`, `Indonésie`],
    [`Liechtenstein`, `Liechtenstein`],
    [`Madagascar`, `Madagascar`],
    [`Malawi`, `Malawi`],
    [`Macao S.A.R., China`, `Macao`],
    [`Qatar`, `Qatar`],
    [`Mauritius`, `Maurice`],
    [`Cyprus`, `Chypre`],
    [`Luxembourg`, `Luxembourg`],
    [`Solomon Islands`, `île Salomon`],
    [`Netherlands Antilles`, `Antilles néerlandaises`],
    [`Netherlands`, `Pays-Bas`],
    [`Spain`, `Espagne`],
    [`Thailand`, `Thailande`],
    [`Guernsey`, `Guernesey`],
    [`Zambia`, `Zambie`],
    [`Jersey`, `Jersey`],
    [`Honduras`, `Honduras`],
    [`Algeria`, `Algérie`],
    [`Brunei`, `Brunei`],
    [`Bhutan`, `Bhoutan`],
    [`Costa Rica`, `Costa Rica`],
    [`Benin`, `Bénin`],
    [`French Polynesia`, `Polynésie Française `],
    [`Dominican Republic`, `République Dominicaine`],
    [`Burundi`, `Burundi`],
    [`Antarctica`, `Antarctique`],
    [`Bulgaria`, `Bulgarie`],
    [`Albania`, `Albanie`],
    [`Republic of Ireland`, `République d'Irlande`],
    [`Croatia`, `Croatie`],
    [`Djibouti`, `Djibouti`],
    [`Iceland`, `Islande`],
    [`Gabon`, `Gabon`],
    [`Guinea-Bissau`, `Guinée-Bissau`],
    [`Hungary`, `Hongrie`],
    [`Micronesia`, `Micronésie`],
    [`Moldova`, `Moldavie`],
    [`Saint Vincent and the Grenadines`, `Saint-Vincent-et-les-Grenadines`],
    [`Saudi Arabia`, `Arabie Saudite`],
    [`Belize`, `Belize`],
    [`Andorra`, `Andorre`],
    [`Bolivia`, `Bolivie`],
    [`Bonaire, Saint Eustatius and Saba`, `Bonaire, Saint-Eustache et Saba`],
    [`French Southern Territories`, `Territoires français du Sud`],
    [`Gambia`, `Gambie`],
    [`Sierra Leone`, `Sierra Leone`],
    [`Togo`, `Togo`],
    [`Niue`, `Niue`],
    [`Somalia`, `Somalie`],
    [`Tokelau`, `Tokelau`],
    [`Uruguay`, `Uruguay`],
    [`Venezuela`, `Venezuela`],
    [`Uzbekistan`, `Ouzbékistan`],
    [`Åland Islands`, `îles Aland`],
    [`Denmark`, `Danemark`],
    [`Chad`, `Tchad`],
    [`Belgium`, `Belgique`],
    [`China`, `Chine`],
    [`Seychelles`, `les Seychelles`],
    [`Yemen`, `Yémen`],
    [`Israel`, `Israël`],
    [`New Zealand`, `Nouvelle-Zélande`],
    [`Isle of Man`, `île de Man`],
    [`Montserrat`, `Montserrat`],
    [`Guadeloupe`, `Guadeloupe`],
    [
      `British Indian Ocean Territory`,
      `Territoire britannique de l'océan Indien`,
    ],
    [`Monaco`, `Monaco`],
    [`Greece`, `Grèce`],
    [`Portugal`, `Portugal`],
    [`El Salvador`, `Le Salvador`],
    [`Christmas Island`, `île de Noël`],
    [`Nigeria`, `Nigeria`],
    [`Switzerland`, `Suisse`],
    [`Sweden`, `Suède`],
    [`Ecuador`, `Equateur`],
    [`San Marino`, `Saint Marin`],
    [`Kiribati`, `Kiribati`],
    [`Mali`, `Mali`],
    [`Mauritania`, `Mauritanie`],
    [`Mozambique`, `Mozambique`],
    [`Tanzania`, `Tanzanie`],
    [`Timor-Leste`, `Timor-Leste`],
    [`Tunisia`, `Tunisie`],
    [`Uganda`, `Ouganda`],
    [`United Kingdom (UK)`, `Royaume-Uni`],
    [`Ukraine`, `Ukraine`],
    [`United States (US)`, `Etats-Unis`],
    [`Zimbabwe`, `Zimbabwe`],
    [`Suriname`, `Suriname`],
    [`Singapore`, `Singapour`],
    [`Syria`, `Syrie`],
    [`Swaziland`, `Swaziland`],
    [`Argentina`, `Argentine`],
    [`Western Sahara`, `Sahara occidental`],
    [`Vatican`, `Vatican`],
    [`Comoros`, `Comores`],
    [`Cook Islands`, `Les îles Cook`],
    [`Vanuatu`, `Vanuatu`],
    [`Gibraltar`, `Gibraltar`],
    [`Ghana`, `Ghana`],
    [`Greenland`, `Groenland`],
    [`Canada`, `Canada`],
    [`Taiwan`, `Taiwan`],
    [`Chile`, `Chili`],
    [`Cape Verde`, `Cap-Vert`],
    [`Anguilla`, `Anguilla`],
    [`Pitcairn`, `Pitcairn`],
    [`Cambodia`, `Cambodge`],
    [`Saint Pierre and Miquelon`, `Saint-Pierre-et-Miquelon`],
    [`Cayman Islands`, `Iles Caïmans`],
    [`Jordan`, `Jordanie`],
    [`Poland`, `Pologne`],
    [`Central African Republic`, `République centrafricaine`],
    [`Japan`, `Japon`],
    [`Bermuda`, `Bermudes`],
    [`Malta`, `Malte`],
    [`Slovenia`, `Slovénie`],
    [`Philippines`, `Philippines`],
    [`Saint Kitts and Nevis`, `Saint-Christophe-et-Niévès`],
    [`Maldives`, `Maldives`],
    [`Macedonia`, `Macédoine`],
    [`Nepal`, `Népal`],
    [`Morocco`, `Maroc`],
    [`Vietnam`, `Vietnam`],
    [`Sudan`, `Soudan`],
    [`Colombia`, `Colombie`],
    [`Armenia`, `Arménie`],
    [`Turkey`, `Turquie`],
    [`Botswana`, `Botswana`],
    [`Burkina Faso`, `Burkina Faso`],
    [`New Caledonia`, `Nouvelle Calédonie`],
    [`Ethiopia`, `Ethiopie`],
    [`French Guiana`, `Guyane Française`],
    [`Grenada`, `Grenade`],
  ],
  pages: [
    [`Company Values`, `Valeurs de l'entreprise`],
    [`Company Lab`, `Laboratoire de l'entreprise`],
    [`Medicine Food Supplements`, `Médecine Compléments alimentaires`],
    [`Shop Homeopathy`, `Magasin Homéopathie`],
    [`Medicine Phytotherapy`, `Médecine Phytothérapie`],
    [`Shop Gemmotherapy`, `Magasin Gemmothérapie`],
    [`Shop Seasonal`, `Magasin Saisons`],
    [`Shop Phytotherapy`, `Magasin Phytothérapie`],
    [`Medicine Homeopathy`, `Médecine Homéopathie`],
    [`Shop Make your Own`, `Magasin Faites-le vous-même`],
    [`Company Story`, `Histoire de l'entreprise`],
    [`Shop Schussler Salts`, `Magasin Sels de Schüssler`],
    [`Shop Oligotherapy`, `Magasin Oligothérapie`],
    [`Medicine Gemmotherapy`, `Médecine Gemmothérapie`],
    [`Medicine Schussler Salts`, `Médecine Sels de Schüssler `],
    [`Home`, `Maison`],
    [`Shop Homeopathy Remedies`, `Magasin Remèdes homéopathiques`],
    [`Health`, `Santé`],
    [`Shop Food Supplements`, `Magasin Compléments alimentaires`],
    [`Medicine Oligotherapy`, `Médecine Oligothérapie`],
    [`Science`, `Science`],
    [`Shop Homepathy Complexes`, `Magasin Complexes homéopathiques `],
    [`Company Quality`, `Qualité de l'entreprise`],
    [`Company Team`, `Equipe de l'entreprise`],
  ],
  shop: [
    [`Avaliable in {nr} variations`, `Disponible en {nr} variantes`],
    [`150ml`, `150ml`],
    [`250ml`, `250ml`],
    [`60ml`, `60ml`],
    [`General information`, `Informations générales`],
    [`Shipping`, `Expédition`],
    [`Select Option`, `Choisir une option`],
    [`Add to Cart`, `Ajouter au panier`],
    [`Granules`, `Granules`],
    [`Globuli`, `Globules`],
    [`General`, `Général`],
    [`Form`, `Forme galénique`],
    [`Drops`, `Gouttes`],
    [`Creme`, `Crème`],
    [`Trituration`, `Trituration`],
    [`Suppository`, `Suppositoires`],
    [`Type`, `Type`],
    [`Phytotherapy`, `Phytothérapie`],
    [`Homeopathy`, `Homéopathie`],
    [`Gemmotherapy`, `Gemmothérapie`],
    [`Substance`, `Substance`],
    [`Type more to see results...`, `Tapez pour voir plus ...  `],
    [`Scale`, `Type de dilutions`],
    [`DH`, `DH`],
    [`CH`, `CH`],
    [`Dilution`, `Dilution`],
    [`variations`, `variations `],
    [`Avaliable in {nr} variations`, `Disponible en {nr} formes galéniques`],
    [`Avaliable in {nr} variation`, `Disponible en {nr} forme galénique`],
    [`Substances`, `Substances`],
    [`Tube 4g`, `Tube 4g`],
    [`Bottle 15g`, `Flacon  15g`],
    [`Size`, `Conditionnement`],
    [`Tube 1g`, `Tube 1g`],
    [`LM`, `LM / Q`],
    [`K`, `K`],
    [`Bottle 10g`, `Flacon 10g`],
    [`Mannit Bottle 60g`, `Flacon 60g base manitol`],
    [`Carrier`, `Liquide`],
    [`NACL`, `NACL`],
    [`Bottle 30ml`, `Flacon 30ml`],
    [`Bottle 10ml`, `Flacon 10ml`],
    [`25° AETH.`, `25° AETH.`],
    [`12 Pieces Adults`, `12 Pièces adultes`],
    [`Water`, `Eau distillée`],
    [`variation`, `variation`],
    [`12 Pieces Kids`, `12 Pièces enfants`],
    [`Lactose Bottle 60g`, `Flacon 60g base lactose`],
    [`E DIST.`, `E DIST.`],
    [`50g`, `50 g`],
    [`Bottle 125ml`, `Flacon 125ml`],
    [`Bottle 60ml`, `Flacon 60ml`],
    [`Bottle 250ml`, `Flacon 250ml`],
    [`Custom Remedy`, `Remède personnalisé `],
    [`Summary`, `Résumé`],
    [`Total: {nr} items`, `Total: {nr} produits`],
    [`Total: {nr} item`, `Total: {nr} produit`],
    [`Cart`, `Panier`],
    [`Checkout`, `Payer`],
    [`Pills 6DH`, `Pillule 6DH`],
  ],
  routes: [
    ['shop', 'magasin'],
    [`payment`, `payments`],
    [`otcs`, `otcs`],
    [`products`, `products`],
    ['company', 'entreprise'],
    ['medicine', 'medecine'],
    ['health', 'sante'],
    ['resources', 'ressources'],
    ['story', 'histoire'],
    ['homeopathy', 'homeopathie'],
    ['gemmotherapy', 'gemmotherapie'],
    ['phytotherapy', 'phytotherapie'],
    ['oligotherapy', 'oligotherapie'],
    ['values', 'valeurs'],
    ['quality', 'qualite'],
    ['team', 'equipe'],
    ['lab', 'laboratoire'],
    ['schussler_salts', 'sels_de_schussler'],
    ['food_supplements', 'complements_alimentaires'],
    ['make_your_own', 'faites_le_vous-meme'],
    ['remedies', 'remedes'],
    ['seasonal', 'produits_saisonniers'],
    ['account', 'compte'],
    ['security', 'securite'],
    ['addresses', 'adresses'],
    ['orders', 'commandes'],
    ['checkout', 'checkout'],
    ['imprint', 'impressum'],
    ['login', 'login'],
    ['news', 'news'],
    ['signup', 'signup'],
    ['contact', 'contact'],
    ['complexes', 'complexes'],
  ],
  message: [
    ['loading...', 'Chargement en cours...'],
    [
      'Your password reset email has been sent.',
      'Votre courriel de réinitialisation de mot de passe a été envoyé.',
    ],
    [`Successfully logged in!`, `Connexion réussie !`],
    [
      'Your session expired, please log in again.',
      'Votre session a expiré, veuillez vous reconnecter.',
    ],
  ],
}
