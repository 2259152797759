import React from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import shortid from 'shortid'
import _ from 'lodash'
import Reveal from 'react-reveal/Reveal'
import Select from 'react-select'

import Footer from '../Footer'
import BackLink from '../BackLink'
import { addToCart } from '../../actions/cartActions'
import formatPrice from '../../utils/formatPrice'
import Settings from '../../Settings'
import urlFormat from '../../utils/urlFormat'
import _t from '../../utils/translate'
import QuantityField from '../../utils/QuantityField'

class SingleSchussler extends React.Component {
  constructor(props) {
    super(props)
    this.addToCartHandler = this.addToCartHandler.bind(this)
    this.selectProduct = this.selectProduct.bind(this)
    this.changeQuantity = this.changeQuantity.bind(this)
    // this.selectProduct()
    this.state = { schusslerSalt: null, product: false, quantity: 1 }
  }

  static getDerivedStateFromProps(nextProps) {
    const { match, schusslerSalts, loading } = nextProps

    if (!loading) {
      const schusslerSalt = _.find(schusslerSalts, (element) => {
        return urlFormat(element.name) === match.params.product
      })
      return { schusslerSalt }
    }

    return null
  }

  addToCartHandler = () => {
    const { product, schusslerSalt, form, quantity } = this.state
    const { addToCart: _addToCart } = this.props

    const newProduct = {
      WcId: product.id,
      price: parseFloat(product.price),
      name: `N°${schusslerSalt.nr} ${schusslerSalt.name}`,
      icon: 'package_schussler',
      info: { form: _t(form, 'schussler_salts') },
      quantity,
    }
    _addToCart(newProduct)
  }

  changeQuantity = (quantity) => this.setState({ quantity })

  selectProduct = (e) => {
    const { products } = this.props

    const schusslerType = e.value.includes('pl') ? 'pills' : 'powder'

    const product = _.find(products, {
      slug: `schussler-salts-${schusslerType}`,
    })
    console.log(product)
    this.setState({ ...this.state, product, form: e.value })
  }

  render() {
    const { language } = this.props
    const { schusslerSalt, product, quantity } = this.state
    const { changeQuantity } = this

    // defaultValue={{
    // 	value: schusslerSalt.avl[0],
    // 	label: _t(schusslerSalt.avl[0], 'schussler_salts')
    // }}

    if (!schusslerSalt) return null

    const schusslerSelect = (
      <Select
        onChange={this.selectProduct}
        isSearchable={false}
        placeholder={_t('Select Option', 'shop')}
        className="schussler_select"
        classNamePrefix="schussler_select"
        options={schusslerSalt.avl.map((element) => ({
          key: shortid.generate(),
          value: element,
          label: _t(element, 'schussler_salts'),
        }))}
      />
    )

    // console.log(options)
    // 	const { loading } = products

    // 	if (!loading && product) {
    // 		// const product = this.findProduct()

    // 		// console.log(product)
    if (schusslerSalt) {
      return (
        <div className="page_content single_product padding_top_140">
          <Helmet>
            <title>{`SN - N°${schusslerSalt.nr} ${schusslerSalt.name}`}</title>
          </Helmet>
          <BackLink
            to={`/${language}/${_t('shop', 'routes')}/${_t(
              'schussler_salts',
              'routes'
            )}`}
          >
            {_t('Back to Schussler Salts')}
          </BackLink>

          <div className="title_with_icon_wrapper">
            <div className="title_with_icon">
              <div className="icon icon_schussler_salts active" />
              <h1>
                N°{schusslerSalt.nr}
                <br />
                {schusslerSalt.name}
              </h1>
            </div>
          </div>
          <div className="single_product_wrapper">
            <div className="product_image">
              <div className="icon icon_schussler_salts" />
              <div className="schussler_image" />
            </div>
            <div className="product_info">
              {Settings.descriptionAllowed && (
                <div
                  className="product_description"
                  dangerouslySetInnerHTML={{
                    __html: schusslerSalt[`dsc_${language}`] || null,
                  }}
                />
              )}

              <div className="info_box_holder">
                <div className="info_box">
                  <h3>{_t('General', 'shop')}</h3>
                  <div>
                    {schusslerSalt.avl.length > 1
                      ? _t('Avaliable in {nr} variations', 'shop', {
                          nr: schusslerSalt.avl.length,
                        })
                      : _t('Avaliable in {nr} variation', 'shop', {
                          nr: schusslerSalt.avl.length,
                        })}

                    {schusslerSalt.avl &&
                      schusslerSalt.avl.map((element) => (
                        <p key={shortid.generate()}>
                          {_t(element, 'schussler_salts')}
                        </p>
                      ))}
                  </div>
                </div>

                <div className="clearfix" />
              </div>

              <div className="buy_section schussler_quantity">
                {schusslerSelect}
                <div className="schussler_right">
                  <span className="price">
                    {product && formatPrice(parseFloat(product.price))}
                  </span>
                  <QuantityField
                    onChange={changeQuantity}
                    quantity={quantity}
                  />
                  <button
                    type="button"
                    className="buy new_line highlight"
                    onClick={this.addToCartHandler}
                    disabled={!product}
                  >
                    {_t('Add to Cart', 'shop')}
                  </button>
                </div>
              </div>
            </div>
            <div className="clearfix" />
          </div>

          <Footer />
        </div>
      )
    }
    return null
  }
}

SingleSchussler.defaultProps = {}

SingleSchussler.propTypes = {}

const mapStateToProps = (state) => ({
  productsLoading: state.products.loading,
  products: state.products.products,
  schusslerSalts: state.singleRemedies.schussler,
  loading: state.singleRemedies.schusslerLoading,
  language: state.ui.language,
})

const SingleSchusslerWrapper = (props) => (
  <Reveal effect="custom_fade_in" key={shortid.generate()}>
    <SingleSchussler {...props} />
  </Reveal>
)

export default connect(mapStateToProps, { addToCart })(SingleSchusslerWrapper)
