import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import shortid from 'shortid'
import _ from 'lodash'

import Spinner from '../Spinner'
import OtcSingle from './OtcSingle'

const OtcDisplay = ({ apiElement, products, match }) => {
    // const sortedSchussler = _.sortBy(schusslerSalts, 'srt')
    if (products.loading) {
        return <Spinner />
    }
    const linkBase = match.path.split(':')[0]
    const filteredProducts = Object.values(products.products).filter(
        element => {
            return _.find(element.categories, cat => cat.slug === 'otc')
        }
    )

    // console.log(filteredProducts)
    const sortedProducts = _.orderBy(filteredProducts, e =>
        parseInt(e.acf.order_index, 10)
    )
    return (
        <div className="otc_holder">
            {!products.loading &&
                sortedProducts.map(product => (
                    <OtcSingle
                        linkBase={linkBase}
                        key={shortid.generate()}
                        product={product}
                    />
                ))}
            <div className="clearfix" />
            <p>{console.log(sortedProducts)}</p>
        </div>
    )
}
//

const mapStateToProps = state => ({
    products: state.products
})

export default connect(mapStateToProps)(withRouter(OtcDisplay))
