import React from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import shortid from 'shortid'
import _ from 'lodash'
import Reveal from 'react-reveal/Reveal'
// import Select from 'react-select'

import Settings from '../../Settings'
import Footer from '../Footer'
import BackLink from '../BackLink'
import { addToCart } from '../../actions/cartActions'
import formatPrice from '../../utils/formatPrice'
import urlFormat from '../../utils/urlFormat'
import _t from '../../utils/translate'
import QuantityField from '../../utils/QuantityField'

class SingleOtc extends React.Component {
  constructor(props) {
    super(props)
    this.addToCartHandler = this.addToCartHandler.bind(this)
    this.selectProduct = this.selectProduct.bind(this)
    this.changeQuantity = this.changeQuantity.bind(this)
    // this.selectProduct()
    this.state = { product: null, quantity: 1 }
  }

  static getDerivedStateFromProps(nextProps) {
    const { match, products, loading } = nextProps

    // console.log(products)
    // console.log(match.params.product)

    if (!loading) {
      const product = _.find(products, (element) => {
        return urlFormat(element.name) === match.params.product
      })
      console.log(product)
      return { product }
    }

    return null
  }

  addToCartHandler = () => {
    const { product, quantity } = this.state
    const { addToCart: _addToCart, language } = this.props

    const newProduct = {
      WcId: product.id,
      price: parseFloat(product.price),
      name: `${_t('OTC: ')}${product.acf[`title_${language}`]}`,
      icon: 'package_unknown',
      info: {},
      quantity,
    }
    _addToCart(newProduct)
  }

  selectProduct = (e) => {
    const { products } = this.props

    const schusslerType = e.value.includes('pl') ? 'pills' : 'powder'

    const product = _.find(products, {
      slug: `schussler-salts-${schusslerType}`,
    })
    // console.log(product)
    this.setState({ ...this.state, product, form: e.value })
  }

  changeQuantity = (quantity) => this.setState({ quantity })

  render() {
    const { language } = this.props
    const { product, quantity } = this.state
    const { changeQuantity } = this

    const imageUrl = product?.acf?.product_image?.sizes?.large

    if (!product) return null
    return (
      <div className="page_content single_product padding_top_140">
        <Helmet>
          <title>{`${product.acf[`title_${language}`]}`}</title>
        </Helmet>
        <BackLink
          to={`/${language}/${_t('shop', 'routes')}/${_t(
            'homeopathy',
            'routes'
          )}/${_t('otcs', 'routes')}`}
        >
          {_t('Back to OTCs')}
        </BackLink>

        <div className="title_with_icon_wrapper">
          <div className="title_with_icon">
            <div className="icon icon_homeopathy active" />
            <h1>{`${product.acf[`title_${language}`]}`}</h1>
          </div>
        </div>
        <div className="single_product_wrapper">
          <div className="product_image">
            <div className="icon icon_homeopathy" />
            {imageUrl && (
              <div
                className="otc_image"
                style={{
                  backgroundImage: `url("${imageUrl}")`,
                }}
              />
            )}
            {!imageUrl && <div className="otc_image" />}
          </div>
          <div className="product_info">
            <div className="product_description">
              {Settings.descriptionAllowed &&
                `description_${language}` in product.acf &&
                product.acf[`description_${language}`] !== '' && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: product.acf[`description_${language}`] || null,
                    }}
                  />
                )}
              {`substances_${language}` in product.acf &&
                product.acf[`substances_${language}`] !== '' && (
                  <React.Fragment>
                    <h2>{_t('Substances')}</h2>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: product.acf[`substances_${language}`] || null,
                      }}
                    />
                  </React.Fragment>
                )}
              {Settings.indicationAllowed &&
                `indication_${language}` in product.acf &&
                product.acf[`indication_${language}`] !== '' && (
                  <React.Fragment>
                    <h2>{_t('Indication')}</h2>
                    <div
                      className="indication"
                      dangerouslySetInnerHTML={{
                        __html: product.acf[`indication_${language}`] || null,
                      }}
                    />
                  </React.Fragment>
                )}
              {Settings.posologyAllowed &&
                `posology_${language}` in product.acf &&
                product.acf[`posology_${language}`] !== '' && (
                  <React.Fragment>
                    <h2>{_t('Posology')}</h2>
                    <div
                      className="posology"
                      dangerouslySetInnerHTML={{
                        __html: product.acf[`posology_${language}`] || null,
                      }}
                    />
                  </React.Fragment>
                )}
            </div>

            <div className="buy_section">
              <span className="price">
                {product && formatPrice(parseFloat(product.price))}
              </span>
              <QuantityField onChange={changeQuantity} quantity={quantity} />
              <button
                type="button"
                className="buy"
                onClick={this.addToCartHandler}
                disabled={!product}
              >
                {_t('Add to Cart', 'shop')}
              </button>
            </div>
          </div>
          <div className="clearfix" />
        </div>

        <Footer />
      </div>
    )
  }
}

SingleOtc.defaultProps = {}

SingleOtc.propTypes = {}

const mapStateToProps = (state) => ({
  productsLoading: state.products.loading,
  products: state.products.products,
  schusslerSalts: state.singleRemedies.schussler,
  loading: state.products.loading,
  language: state.ui.language,
})

const SingleOtcWrapper = (props) => (
  <Reveal effect="custom_fade_in" key={shortid.generate()}>
    <SingleOtc {...props} />
  </Reveal>
)

export default connect(mapStateToProps, { addToCart })(SingleOtcWrapper)
