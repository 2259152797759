export const en = {
            schussler_salts: [
                  ['pl_6', 'Pills 6DH'],
                  ['pl_12', 'Pills 12DH'],
                  ['p_6', 'Powder 6DH'],
                  ['p_12', 'Powder 12DH'],
                  ['p', 'Powder']
            ],
            general: [
                  ['test', 'Test'],
                  ['test', 'Test'],
                  ['test', 'Test'],
                  ['test', 'Test']
            ]
      }
