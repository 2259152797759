import React from 'react'
import { withRouter } from 'react-router-dom'
import { LanguageNavLink } from '../utils/LanguageLink'
import _t from '../utils/translate'
// import * as Icons from './Icons'

import { colors } from '../img/colors'
// import { ReactComponent as BG } from '../img/icons_secondary_menu/icon_homeopathy.svg'

const SecondaryMenuIcon = ({ location, title, icon, width, height, scope }) => {
	// const IconKey = Icons[`Icon${icon}`]
	// console.log(IconKey)

	return (
		<LanguageNavLink to={`/${scope}/${_t(icon, 'routes')}`}>
			<div
				style={{
					width: '143px',
					textAlign: 'center',
					color: colors.green,
					display: 'inline-block',
					margin: '0 15px',
					fontSize: '12px'
				}}
				// to="asdf"
			>
				<div className={`icon icon_secondary  icon_${icon}`} />
				<div className={`icon icon_secondary  active icon_${icon}`} />
				<p>{title}</p>
			</div>
		</LanguageNavLink>
	)
}
// alert(SecondaryMenuIcon)

// <IconKey width={width} height={height} />

export default withRouter(SecondaryMenuIcon)
