import { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import ReactGA from 'react-ga'

import Settings from '../Settings'
import _t from '../utils/translate'
import { loadLanguage } from '../actions/pagesActions'

const buildSitemap = false

const TryToLogPage = userId => {
  ReactGA.set({ userId })
  // console.log(userId)
  if (document.title !== 'loading...') {
    ReactGA.pageview(window.location.pathname)
    // console.log(
    //  'Page Load: ' + window.location.pathname + '\n' + document.title
    // )
  } else {
    console.log('waiting...')
    window.setTimeout(TryToLogPage, 500)
  }
}

const translatePath = (path, language) =>
  `${path
    .split('/')
    .filter(part => part !== '')
    .map(part => {
      if (part === 'en') return language
      if (part !== '') return _t(part, 'routes', {}, language)
      return null
    })
    .join('/')}/`

class PageLoad extends Component {
  constructor(props) {
    super(props)
    this.checkLanguage = this.checkLanguage.bind(this)
  }

  componentDidMount() {
    // window.setTimeout(TryToLogPage, 500)
    this.checkLanguage()
  }

  componentDidUpdate(prevProps) {
    // if (this.props.location.pathname !== prevProps.location.pathname) {
    // console.log(this.props.location.pathname.split('/~')[0])
    // console.log(prevProps.location.pathname.split('/~')[0])
    if (
      this.props.location.pathname.split('/~')[0] !==
      prevProps.location.pathname.split('/~')[0]
    ) {
      // console.log('sroll to top')
      window.scrollTo(0, 0)
      this.checkLanguage()
      window.setTimeout(() => TryToLogPage(this.props.login), 500)
    }
  }

  checkLanguage() {
    const { language, loadLanguage } = this.props
    // const baseUrl = `${Settings.server}${Settings.baseName}`

    const path = this.props.location.pathname.replace(Settings.baseName, '')

    // console.log('Location:')
    // console.log(this.props.location.pathname)
    // console.log('baseUrl:')
    // console.log(baseUrl)
    // console.log('Path:')

    // if (language === 'en') {

    if (buildSitemap) {
      if (!global?.paths) global.paths = {}
      global.paths[path] = path
      console.log(global.paths)

      var xml = ''
      Object.values(global.paths).map(path => {
        // const pathFr = translatePath(path, 'fr')
        // console.log(pathFr)

        const basePath = 'https://schmidt-nagel.ch/'

        xml = `${xml}
        <url>
          <loc>${basePath}${path}</loc>
          <xhtml:link
                     rel="alternate"
                     hreflang="en"
                     href="${basePath}${path}"/>
          <xhtml:link
                     rel="alternate"
                     hreflang="fr"
                     href="${basePath}${translatePath(path, 'fr')}"/>
          <xhtml:link
                     rel="alternate"
                     hreflang="de"
                     href="${basePath}${translatePath(path, 'de')}"/>
          <xhtml:link
                     rel="alternate"
                     hreflang="it"
                     href="${basePath}${translatePath(path, 'it')}"/>
        </url>`
      })
      console.log(xml)

      console.log(path)
    }
    let newLanguage = this.props.location.pathname.split('/')[1]
    // if (newLanguage.length !== 2)
    // newLanguage = path.split('/')[1]
    if (newLanguage !== language) {
      if (Settings.supportedLanguages.includes(newLanguage)) {
        console.log(`change language: ${newLanguage}`)
        loadLanguage(newLanguage)
      } else {
        console.error(`Language code not found: ${newLanguage}`)
        loadLanguage('fr')
      }
    }
  }

  render() {
    return this.props.children
  }
}

const mapStateToProps = state => ({
  login: state.user.login,
  language: state.ui.language
})

export default withRouter(connect(mapStateToProps, { loadLanguage })(PageLoad))
